import { ActionCreators } from 'redux-undo';
import { BackButton } from '../BackButton/BackButton';
import { Button, Col, Row, Typography } from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import { HomeButton, Location, PhoneSmall } from 'src/shared/icons';
import { goToPage } from 'src/shared/redux/routing/routingSlice';
import { resetAppointmentData } from 'src/shared/redux/appointment/appointmentSlice';
import { resetFilters } from 'src/shared/redux/filter/filterSlice';
import { useAppDispatch } from 'src/hooks';
import { useGetWidgetInfoQuery } from 'src/shared/redux/api/apiSlice';
import Link from 'antd/es/typography/Link';
import React from 'react';
import styles from './styles.module.scss';

export const FooterComponent = ({ page }: { page: string }) => {
  const dispatch = useAppDispatch();
  const { data: widgetInfo } = useGetWidgetInfoQuery('');

  const handleGoHome = () => {
    dispatch(ActionCreators.jumpToPast(0));
    dispatch(resetAppointmentData());
    dispatch(resetFilters());
  };

  return (
    <Footer className={styles.Footer}>
      <Row className={styles.FooterRow}>
        <Col>
          <Row>
            <BackButton clearDoctor={true} disabled={page === 'home'} />
            <Button disabled={page === 'home'} className={styles.HomeButton} onClick={handleGoHome}>
              <HomeButton className={'IconFill'} />
              <Typography>В начало</Typography>
            </Button>
          </Row>
        </Col>
        <Col>
          <Row className={styles.FooterRightRow}>
            <Row className={styles.FooterSmallRow}>
              <Button className={styles.AddressButton} onClick={() => dispatch(goToPage('addresses'))}>
                <Location className={styles.FooterIcon + ' IconFill'} />
                <Typography className={styles.TextAddress}>Адреса на карте</Typography>
              </Button>
            </Row>
            <Row className={styles.FooterSmallRow}>
              <PhoneSmall className={styles.FooterIcon + ' IconFill'} />
              <Link href={`tel:${widgetInfo?.phoneNumber}`} className={styles.PhoneNumber}>
                {widgetInfo?.phoneNumber ?? ''}
              </Link>
            </Row>
          </Row>
        </Col>
      </Row>
    </Footer>
  );
};
